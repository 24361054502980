<template>
  <div class="s-home">
    <template v-for="(selected_component, index) in $root.selected_components">
      <component
        :is="'section-' + selected_component.name"
        :section="selected_component"
        :key="'component' + selected_component.id"
        :id="selected_component.name + '-' + selected_component.id"
        class="s-section"
        @hook:mounted="create_observer(selected_component)"
      />
      <span
        :class="{ dark: selected_component.dark_mode }"
        class="display-block"
        :key="`ad_${index}`"
        v-if="
          !$root.block_dfp &&
          index % 3 === 0 &&
          index != 0 &&
          index + 1 != $root.selected_components.length
        "
      >
        <element-ad name="sidearm-dfp-1"
      /></span>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import IntersectionObserver from "intersection-observer-polyfill";

export default {
  name: "Home",
  data: () => ({}),
  components: {
    SectionStories: () => import("@/components/sections/SectionStories.vue"),
    SectionHero: () => import("@/components/sections/SectionHero.vue"),
    SectionSeries: () => import("@/components/sections/SectionSeries.vue"),
    SectionContentstream: () =>
      import("@/components/sections/SectionContentStream.vue"),
    SectionVideos: () => import("@/components/sections/SectionVideos.vue"),
    SectionPlayers: () => import("@/components/sections/SectionPlayers.vue"),
    SectionRoster: () => import("@/components/sections/SectionRosters.vue"),
    SectionSchedule: () => import("@/components/sections/SectionSchedule.vue"),
    SectionCoverage: () => import("@/components/sections/SectionCoverage.vue"),
    SectionCustom: () => import("@/components/sections/SectionCustom.vue"),
    SectionGalleries: () =>
      import("@/components/sections/SectionGalleries.vue"),
  },
  methods: {
    create_observer(selected_component) {
      if (selected_component.menuInclude) {
        this.$nextTick(() => {
          const target = document.getElementById(
            selected_component.name + "-" + selected_component.id
          );

          const observer = new IntersectionObserver(
            (el) => {
              if (el[0].isIntersecting) {
                this.$emit("change-section", el[0].target.id);
              }
            },
            {
              rootMargin: "-49% 0px -50% 0px",
              threshold: 0,
            }
          );

          observer.observe(target);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.s-home {
  min-height: 100vh;
}
</style>
